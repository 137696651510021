import { defaultHorseWeight, defaultRiderWeight } from '@/lib/constants'
import { average } from '@/lib/helpers/numeric'
import { secondsToHMS } from '@/lib/helpers/convert'
import { disciplinesAvailable } from '@/lib/constants'
import tailwind from '@/lib/tailwind'

/**
 *
 * @param {Object} data         All this data are needed :
 *                                  session from useSession hook,
 *                                  jumps from useSessionJump hook,
 *                                  stridesStatistics from useSessionStridesStatistics hook,
 *                                  strides from useSessionStrides hook
 * @param {Number} startTime    Start time of stats in seconds
 * @param {Number} endTime      End time of stats in seconds
 */
const getFasterStride = (data, startTime = 0, endTime = null) => {
    if (!(data?.stridesStatistics?.length > 0) || !(data?.strides?.length > 0)) {
        return null
    }

    let maxIndex = null
    let maxValue = 0

    for (let strideIdx = 0; strideIdx < data.strides?.length; strideIdx++) {
        if (data.strides[strideIdx].pos.time < startTime || data.strides[strideIdx].pos.time > endTime) {
            continue
        }

        if (data.stridesStatistics[strideIdx]?.speed?.avg > maxValue) {
            maxIndex = strideIdx
            maxValue = data.stridesStatistics[strideIdx].speed.avg
        }
    }

    if (maxIndex === null) return null

    return { stride: data.strides[maxIndex], strideStatistics: data.stridesStatistics[maxIndex] }
}

/**
 *
 * @param {Object} data         All this data are needed :
 *                                  session from useSession hook,
 *                                  jumps from useSessionJump hook,
 *                                  stridesStatistics from useSessionStridesStatistics hook,
 *                                  strides from useSessionStrides hook
 * @param {Number} startTime    Start time of stats in seconds
 * @param {Number} endTime      End time of stats in seconds
 * @param {String} chunkType    Type of chunks 'length', 'duration'
 * @param {Number} chunkLength  Length of chunck
 *                              False: return all gaits
 * @returns
 */
const calculateStatisticsByChunk = (data, startTime = 0, endTime = null, chunkType = 'distance', chunkLength = 200) => {
    let statistics = []

    switch (chunkType) {
        case 'duration':
            const duration = endTime - startTime
            const countChunks = Math.ceil(duration / chunkLength)
            for (let index = 0; index < countChunks; index++) {
                statistics.push(
                    calculateStatistics(data, startTime + index * chunkLength, startTime + (index + 1) * chunkLength)
                )
            }
            break
        case 'distance':
            let chunkStartTime = startTime
            let chunkEndTime = endTime
            let jumps = [...data.jumps] //if not done it will modify the global object
            for (let strideIdx = 0; strideIdx < data.strides?.length; strideIdx++) {
                if (data.strides[strideIdx].pos.time < startTime || data.strides[strideIdx].pos.time > endTime) {
                    continue
                }

                let totalDistance = 0
                while (totalDistance < chunkLength && strideIdx < data.strides?.length) {
                    totalDistance += data.stridesStatistics[strideIdx].distance.sum
                    chunkEndTime = data.strides[strideIdx].pos.time
                    chunkEndTime -= 0.01
                    //START JUMPS
                    for (let idx = 0; idx < jumps?.length; idx++) {
                        const jump = jumps[idx]
                        if (jump.start.time < chunkStartTime || jump.end.time > chunkEndTime) {
                            continue
                        }
                        totalDistance += jump?.statistics?.distance?.sum
                        jumps.splice(idx, 1) //to remove jump when added to distance
                    }
                    //END JUMPS
                    strideIdx++
                }

                statistics.push(calculateStatistics(data, chunkStartTime, chunkEndTime))
                chunkStartTime = chunkEndTime
            }
            break
    }
    return statistics
}

/**
 *
 * @param {Object} data         All this data are needed :
 *                                  session from useSession hook,
 *                                  jumps from useSessionJump hook,
 *                                  stridesStatistics from useSessionStridesStatistics hook,
 *                                  strides from useSessionStrides hook
 * @param {Number} startTime    Start time of stats in seconds
 * @param {Number} endTime      End time of stats in seconds
 *                              False: return all gaits
 * @returns
 */
const calculateStatistics = (data, startTime = 0, endTime = null) => {
    console.time('live-stats')

    const totalWeight = getTotalWeight(data.session?.horse?.weight, data.session?.rider)

    endTime = endTime ?? data.session.elapsed_time

    const initialValues = {
        stride_left_hand_count: 0,
        stride_straight_hand_count: 0,
        stride_right_hand_count: 0,
        stride_gait_count: 0,
        stride_gait_countRegular: 0,
        stride_speed_average: 0,
        stride_speed_max: 0,
        stride_speed_min: Infinity,
        stride_yaw_average: 0,
        stride_roll_average: 0,
        stride_pitch_average: 0,
        stride_energy_average: 0,
        stride_distance_average: 0,
        stride_distance_max: 0,
        stride_distance_min: Infinity,
        stride_bpm_count: 0,
        stride_bpm_average: 0,
        stride_bpm_max: 0,
        stride_bpm_min: Infinity,
        stride_duration_average: 0,
        stride_duration_max: 0,
        stride_duration_min: Infinity,
        stride_height_average: 0,
        stride_height_max: 0,
        stride_height_min: Infinity,
        stride_classification: [], //here it will de an object returned {regularity,values,...}
        stride_power_average: 0,
        strides_duration: 0,
        strides_distance: 0,
        strides_energy: 0,
        jump_count: 0,
        gait_duration: 0,
        gait_distance: 0,
        gait_energy: 0,
        gait_transition_count: 0,
        stride_cadence_average: 0,
    }

    let gaits = {
        canter: {
            ...initialValues,
            cadence_coefficient: 1,
        },
        trot: {
            ...initialValues,
            cadence_coefficient: 0.5,
        },
        walk: {
            ...initialValues,
            cadence_coefficient: 1,
        },
        standing: {
            ...initialValues,
            cadence_coefficient: 0,
        },
    }

    let haveBpm = false
    let energy = 0
    let distance = 0
    let duration = 0
    let jump_duration_average = 0
    let jump_distance_average = 0
    let jump_height_average = 0
    let jump_z_power_average = 0
    let jump_speed_average = 0
    let jump_energy_average = 0
    let jump_pitch_average = 0
    let jump_roll_average = 0
    let jump_yaw_average = 0
    let jump_pitch_max = 0
    let jump_classification = [] //here it will de an object returned {regularity,values,...}
    let jumpCount = 0
    let strideCount = 0
    let stride_before_distance_average = 0
    let stride_before_height_average = 0
    let stride_after_distance_average = 0
    let stride_after_height_average = 0
    let firstHalfSection = { pitch: [] }
    let secondHalfSection = { pitch: [] }
    const halfTime = (startTime + endTime) / 2

    for (let idx = 0; idx < data.jumps?.length; idx++) {
        const jump = data.jumps[idx]

        if (jump.start.time < startTime || jump.end.time > endTime) {
            continue
        }

        gaits[jump.gait].jump_count += 1
        gaits[jump.gait].gait_duration += jump?.statistics?.duration?.sum || 0
        gaits[jump.gait].gait_distance += jump?.statistics?.distance?.sum || 0
        gaits[jump.gait].gait_energy += jump?.statistics?.energy?.sum || 0

        duration += jump?.statistics?.duration?.sum || 0
        distance += jump?.statistics?.distance?.sum || 0
        jump_height_average += jump?.statistics?.height?.max || 0
        jump_z_power_average += jump?.statistics?.power?.max || 0
        jump_speed_average += jump?.statistics?.speed?.avg || 0
        jump_energy_average += jump?.statistics?.energy?.sum || 0
        energy += jump?.statistics?.energy?.sum || 0
        jump_pitch_average += jump?.statistics?.pitch?.max || 0
        jump_roll_average += jump?.statistics?.roll?.avg || 0
        jump_yaw_average += jump?.statistics?.yaw?.avg || 0
        if (jump_pitch_max < jump?.statistics?.pitch?.max) {
            jump_pitch_max = jump?.statistics?.pitch?.max || 0
        }
        jump_classification = [...jump_classification, jump?.area] //we add all the area in an array

        const strideStatisticsBefore = data.stridesStatistics?.[jump.strides.last]
        const strideStatisticsAfter = data.stridesStatistics?.[jump.strides.last + 1]
        stride_before_distance_average += strideStatisticsBefore?.distance.sum || 0
        stride_before_height_average += strideStatisticsBefore?.height.max || 0
        stride_after_distance_average += strideStatisticsAfter?.distance.sum || 0
        stride_after_height_average += strideStatisticsAfter?.height.max || 0

        jumpCount++
    }

    if (jumpCount > 0) {
        //! here we could use the global variables duration and distance because the strides are not yet calculated
        jump_duration_average = duration / jumpCount
        jump_distance_average = distance / jumpCount
        jump_height_average = jump_height_average / jumpCount
        jump_z_power_average = jump_z_power_average / jumpCount
        jump_speed_average = jump_speed_average / jumpCount
        jump_energy_average = (jump_energy_average / jumpCount) * totalWeight
        jump_pitch_average = jump_pitch_average / jumpCount
        jump_roll_average = jump_roll_average / jumpCount
        jump_yaw_average = jump_yaw_average / jumpCount

        jump_classification = regularityAndClassification(jump_classification) //transform the array into an object with regularity etc.

        stride_before_distance_average = stride_before_distance_average / jumpCount
        stride_before_height_average = stride_before_height_average / jumpCount
        stride_after_distance_average = stride_after_distance_average / jumpCount
        stride_after_height_average = stride_after_height_average / jumpCount
    }

    let stride_gait = null

    const stridesIndexBeforeOrAfterJump = data.jumps
        ?.map((jump) => (jump.strides.last ? [jump.strides.last, jump.strides.last + 1] : []))
        .flat()

    let previousGait = null

    for (let strideIdx = 0; strideIdx < data.strides?.length; strideIdx++) {
        const currentStride = data.strides[strideIdx]
        if (!currentStride?.pos?.time) {
            console.warn('aie')
            continue
        }

        if (currentStride.pos.time < startTime || currentStride.pos.time > endTime) {
            continue
        }

        const currentStrideStatistics = data.stridesStatistics?.[strideIdx]
        // console.log('currentStrideStatistics', currentStrideStatistics)

        if (!currentStrideStatistics) {
            continue
        }

        if (currentStrideStatistics['id'] !== currentStride['id']) {
            console.error('Not the same stride ids', currentStrideStatistics['id'], currentStride['id'])
            debugger
        }

        duration += currentStrideStatistics.duration.sum || 0 // total duration (all strides + all jumps)
        distance += currentStrideStatistics.distance.sum || 0 // total distance (all strides + all jumps)
        strideCount++

        const currentGait = currentStride.gait.toLowerCase()
        if (currentGait !== previousGait) {
            gaits[currentGait].gait_transition_count += 1
            previousGait = currentGait
        }

        switch (currentStrideStatistics['hand']) {
            case 'l':
                gaits[currentGait].stride_left_hand_count += 1
                break
            case 's':
                gaits[currentGait].stride_straight_hand_count += 1
                break
            case 'r':
                gaits[currentGait].stride_right_hand_count += 1
                break
        }

        //#### sort data by hand
        // if (strideIdx > 1 && strideIdx <= data.strides.length) {
        //     const { pos: prevPos } = data.strides[strideIdx - 2]
        //     const { pos: curPos } = data.strides[strideIdx - 1]
        //     const { pos: nextPos } = data.strides[strideIdx]

        //     const ux = Math.round(parseFloat(curPos.x - prevPos.x) * 100) / 100
        //     const uy = Math.round(parseFloat(curPos.y - prevPos.y) * 100) / 100
        //     const u2x = Math.round(parseFloat(nextPos.x - curPos.x) * 100) / 100
        //     const u2y = Math.round(parseFloat(nextPos.y - curPos.y) * 100) / 100
        //     if (strideIdx < 40) {
        //         const teta1 =
        //             Math.round(parseFloat(Math.atan(Math.abs(uy) / Math.abs(ux)) * (180 / Math.PI)) * 100) / 100
        //         // const teta1 = Math.round(parseFloat(Math.atan(uy / ux) * (180 / Math.PI)) * 100) / 100
        //         // const orientation1 = 180 - teta1
        //         const teta2 =
        //             Math.round(parseFloat(Math.atan(Math.abs(u2y) / Math.abs(u2x)) * (180 / Math.PI)) * 100) / 100
        //         // const teta2 = Math.round(parseFloat(Math.atan(u2y / u2x) * (180 / Math.PI)) * 100) / 100
        //         // const orientation2 = 180 - teta2
        //         const isRight = teta2 > teta1
        //         const isStraight = Math.abs(teta2 - teta1) < 12
        //         const direction = isStraight ? 'straight' : isRight ? 'right' : 'left'
        //         // console.log(`${strideIdx} ${teta1} ${teta2} ${direction}`)
        //     }
        // }
        //####

        // # STATISTICS HALF SECTION
        if (currentStride.pos.time < halfTime) {
            firstHalfSection['pitch'].push(currentStrideStatistics.pitch.avg)
        } else {
            secondHalfSection['pitch'].push(currentStrideStatistics.pitch.avg)
        }
        //####

        gaits[currentGait].stride_gait_count += 1
        gaits[currentGait].strides_duration += currentStrideStatistics.duration.sum
        gaits[currentGait].gait_duration += currentStrideStatistics.duration.sum
        gaits[currentGait].stride_cadence_average += 1
        gaits[currentGait].strides_distance += currentStrideStatistics.distance.sum // distance by gait
        gaits[currentGait].strides_energy += currentStrideStatistics.energy.sum // energy by gait
        gaits[currentGait].gait_distance += currentStrideStatistics.distance.sum
        gaits[currentGait].gait_energy += currentStrideStatistics.energy.sum

        energy += currentStrideStatistics.energy.sum

        // see https://github.com/Alogo-Analysis/issues-private/issues/6
        if (gaits[currentGait].stride_speed_max < currentStrideStatistics.speed.avg) {
            gaits[currentGait].stride_speed_max = currentStrideStatistics.speed.avg
        }

        // ignore the strides before and after the jumps and the strides with two differents gaits
        if (stridesIndexBeforeOrAfterJump.includes(strideIdx) || currentStride.changeover === true) {
            continue
        }

        // todo 20210928 currently not implemented because we are not sure yet if we have to keep those strides into the global statistics
        // if (currentStride.indoor === 1) {
        //     // console.log('skip indoor stride')
        //     continue
        // }

        gaits[currentGait].stride_gait_countRegular += 1
        gaits[currentGait].stride_speed_average += currentStrideStatistics.speed.avg

        if (gaits[currentGait].stride_speed_min > currentStrideStatistics.speed.avg) {
            gaits[currentGait].stride_speed_min = currentStrideStatistics.speed.avg
        }
        gaits[currentGait].stride_yaw_average += currentStrideStatistics.yaw.avg
        gaits[currentGait].stride_roll_average += currentStrideStatistics.roll.avg
        gaits[currentGait].stride_pitch_average += currentStrideStatistics.pitch.avg
        gaits[currentGait].stride_energy_average += currentStrideStatistics.energy.sum
        gaits[currentGait].stride_distance_average += currentStrideStatistics.distance.sum
        if (gaits[currentGait].stride_distance_max < currentStrideStatistics.distance.sum) {
            gaits[currentGait].stride_distance_max = currentStrideStatistics.distance.sum
        }
        if (gaits[currentGait].stride_distance_min > currentStrideStatistics.distance.sum) {
            gaits[currentGait].stride_distance_min = currentStrideStatistics.distance.sum
        }

        gaits[currentGait].stride_classification = [...gaits[currentGait].stride_classification, currentStride.area]

        if (currentStride.bpm) {
            haveBpm = true
            gaits[currentGait].stride_bpm_count += 1
            gaits[currentGait].stride_bpm_average += currentStride.bpm
            if (gaits[currentGait].stride_bpm_max < currentStride.bpm) {
                gaits[currentGait].stride_bpm_max = currentStride.bpm
            }
            if (gaits[currentGait].stride_bpm_min > currentStride.bpm) {
                gaits[currentGait].stride_bpm_min = currentStride.bpm
            }
        }
        gaits[currentGait].stride_duration_average += currentStrideStatistics.duration.sum
        if (gaits[currentGait].stride_duration_max < currentStrideStatistics.duration.sum) {
            gaits[currentGait].stride_duration_max = currentStrideStatistics.duration.sum
        }
        if (gaits[currentGait].stride_duration_min > currentStrideStatistics.duration.sum) {
            gaits[currentGait].stride_duration_min = currentStrideStatistics.duration.sum
        }
        gaits[currentGait].stride_height_average += currentStrideStatistics.height.max
        if (gaits[currentGait].stride_height_max < currentStrideStatistics.height.max) {
            gaits[currentGait].stride_height_max = currentStrideStatistics.height.max
        }
        if (gaits[currentGait].stride_height_min > currentStrideStatistics.height.max) {
            gaits[currentGait].stride_height_min = currentStrideStatistics.height.max
        }
        gaits[currentGait].stride_power_average += currentStrideStatistics.power.max
    } //end of for on strides

    //calculate the average for each half statistics
    firstHalfSection['pitch'] = average(firstHalfSection['pitch'])
    secondHalfSection['pitch'] = average(secondHalfSection['pitch'])

    // Define higher gait
    if (gaits['canter'].stride_gait_countRegular > 0) {
        stride_gait = 'canter'
    } else if (gaits['trot'].stride_gait_countRegular > 0) {
        stride_gait = 'trot'
    } else if (gaits['walk'].stride_gait_countRegular > 0) {
        stride_gait = 'walk'
    } else {
        stride_gait = 'standing'
    }

    if (jump_height_average > 0) {
        jump_height_average = jump_height_average + (data.session?.horse?.sensor_height ?? 0) / 100
    }

    let all = { ...initialValues }
    const keys = Object.keys(initialValues)
    let averageKeys = []
    Object.keys(gaits).forEach(function (gait) {
        gaits[gait].strides_energy *= totalWeight // Watts

        if (gaits[gait].stride_gait_countRegular > 0) {
            gaits[gait].stride_cadence_average =
                (gaits[gait].stride_cadence_average / (gaits[gait].strides_duration / 60)) *
                gaits[gait]['cadence_coefficient']

            gaits[gait].stride_speed_average = gaits[gait].stride_speed_average / gaits[gait].stride_gait_countRegular
            gaits[gait].stride_yaw_average = gaits[gait].stride_yaw_average / gaits[gait].stride_gait_countRegular
            gaits[gait].stride_roll_average = gaits[gait].stride_roll_average / gaits[gait].stride_gait_countRegular
            gaits[gait].stride_pitch_average = gaits[gait].stride_pitch_average / gaits[gait].stride_gait_countRegular
            gaits[gait].stride_energy_average =
                (gaits[gait].stride_energy_average / gaits[gait].stride_gait_countRegular) * totalWeight
            gaits[gait].stride_duration_average =
                gaits[gait].stride_duration_average / gaits[gait].stride_gait_countRegular
            gaits[gait].stride_bpm_average =
                gaits[gait].stride_bpm_count > 0 ? gaits[gait].stride_bpm_average / gaits[gait].stride_bpm_count : 0
            gaits[gait].stride_distance_average =
                gaits[gait].stride_distance_average / gaits[gait].stride_gait_countRegular
            gaits[gait].stride_height_average = gaits[gait].stride_height_average / gaits[gait].stride_gait_countRegular
            gaits[gait].stride_power_average = gaits[gait].stride_power_average / gaits[gait].stride_gait_countRegular
            //Calculate the standard deviation / transform array to a value

            gaits[gait].stride_classification = regularityAndClassification(gaits[gait].stride_classification, 100) //transform the array into an object with regularity etc.

            //ALL GAITS
            for (let index = 0; index < keys.length; index++) {
                const key = keys[index]
                const keySplitted = key.split('_')
                const kindOfKey = keySplitted[keySplitted.length - 1]

                switch (kindOfKey) {
                    case 'count':
                    case 'countRegular':
                        all[key] += gaits[gait][key]
                        break
                    case 'average':
                        all[key] += gaits[gait][key] * gaits[gait]['stride_gait_countRegular']
                        averageKeys.push(key)
                        break
                    case 'max':
                        if (all[key] < gaits[gait][key]) {
                            all[key] = gaits[gait][key]
                        }
                        break
                    case 'min':
                        if (all[key] > gaits[gait][key]) {
                            all[key] = gaits[gait][key]
                        }
                        break
                    case 'stddev':
                        //ignore
                        break
                    case 'regularity':
                        all[key] += gaits[gait][key] * gaits[gait]['stride_gait_countRegular']
                        averageKeys.push(key)
                        break
                    case 'duration':
                        all[key] += gaits[gait][key]
                        break
                    case 'distance':
                        all[key] += gaits[gait][key]
                        break
                    case 'energy':
                        all[key] += gaits[gait][key]
                        break
                    case 'classification':
                        all[key] += gaits[gait][key] * gaits[gait]['stride_gait_countRegular']
                        averageKeys.push(key)
                        break
                    default:
                        console.warn(`Key not managed ${key}`)
                }
            }
        }
    })

    //average for the 'all' gait keys
    const uniqueAverageKeys = [...new Set(averageKeys)]
    for (let index = 0; index < uniqueAverageKeys.length; index++) {
        const key = uniqueAverageKeys[index]
        all[key] = Math.round((all[key] / all['stride_gait_countRegular']) * 10000) / 10000
    }

    //bpm is different and can be empty in some gait
    if (haveBpm) {
        let count = 0
        let avg = 0
        Object.keys(gaits).forEach(function (gait) {
            count += gaits[gait]['stride_bpm_count']
            avg += gaits[gait]['stride_bpm_average'] * gaits[gait]['stride_bpm_count']
        })
        all['stride_bpm_average'] = Math.round(avg / count)
    }

    energy = energy * totalWeight // Watts

    console.timeEnd('live-stats')

    return {
        haveBpm,
        startTime,
        endTime,
        stride_gait: stride_gait,
        elapsed_time: secondsToHMS(endTime - startTime, 3),
        energy: energy, // Watts
        distance: distance,
        duration: duration,
        // duration: secondsToHMS(duration, 3),//what was done before but not the right place to do the convertion
        jump_count: jumpCount,
        stride_count: strideCount,
        jump_height_average: jump_height_average,
        jump_z_power_average: jump_z_power_average,

        jump_speed_average: jump_speed_average,
        jump_energy_average: jump_energy_average,
        jump_pitch_average: jump_pitch_average,
        jump_roll_average: jump_roll_average,
        jump_yaw_average: jump_yaw_average,
        jump_pitch_max: jump_pitch_max,
        jump_duration_average: jump_duration_average,
        jump_distance_average: jump_distance_average,
        jump_classification: jump_classification,

        stride_before_distance_average: stride_before_distance_average,
        stride_before_height_average: stride_before_height_average,
        stride_after_distance_average: stride_after_distance_average,
        stride_after_height_average: stride_after_height_average,

        //half statistics
        firstHalfSectionPitch: firstHalfSection['pitch'],
        secondHalfSectionPitch: secondHalfSection['pitch'],

        gaits: { ...gaits, all },
    }
}

const colorThresholds = {
    duration: 1, // seconds
    strideDistance: 5, // meters
    jumpDistance: 8, // meters
    strideDuration: 0.8, // seconds
    jumpDuration: 1.2, // seconds
    jumpHeight: 0.8, // meters
    strideHeight: 0.2, // meters
    jumpPower: 8, // G
    stridePower: 4, // G
    speed: 500, // meter/min
    angle: 10, // degrees
    pitchAngle: 45, // degrees
    candence: 100, // strides/min
    intensity: 50, //
    energy: 5000, // Watts
    energyMaxPerMinute: 150, //Watts
    bpm: 200, //bpm
    speed_stddev: 80, //std
    distance_stddev: 0.6, //std
    height_stddev: 0.08,
    roll_stddev: 4,
    pitch_stddev: 4,
    yaw_stddev: 4,
    power_stddev: 0.5, //std
    intensity_stddev: 3, //std
    energy_stddev: 220, //std
}

const equilibriumTresholds = {
    pitch: 5,
    yaw: 5,
    roll: 5,
}

const healthEventTresholds = {
    marechal: {
        warning: {
            value: 6,
            unit: 'week',
        },
        danger: {
            value: 8,
            unit: 'week',
        },
    },
    vaccin: {
        warning: {
            value: 11,
            unit: 'month',
        },
        danger: {
            value: 12,
            unit: 'month',
        },
    },
    dentist: {
        warning: {
            value: 11,
            unit: 'month',
        },
        danger: {
            value: 12,
            unit: 'month',
        },
    },
}

const standardDeviation = (arr, mean) => {
    return (
        Math.round(
            Math.sqrt(
                arr.reduce((acc, val) => acc.concat((val - mean) ** 2), []).reduce((acc, val) => acc + val, 0) /
                    arr.length
            ) * 1000
        ) / 1000
    )
}

const sum = (values) =>
    values.reduce(function (a, b) {
        return a + b
    }, 0)

const regularityAndClassification = (values, outlayerPercent = 30) => {
    const nbValues = values.length
    if (nbValues < 3) {
        outlayerPercent = 99
    }
    const average = sum(values) / nbValues
    let classification = []
    let regularValues = [] //regular values to calculate the regularity scoring
    let regularCount = 0
    let unsualCount = 0
    for (let index = 0; index < nbValues; index++) {
        const element = values[index]
        let unusual = false
        let regular = false
        const abs = Math.abs(average - element)
        const percent = (abs * 100) / average
        // console.log(percent + '%' + ' ' + abs + ' ' + element + ' ' + average)
        if (percent > outlayerPercent) {
            unusual = true
            unsualCount++
        } else {
            // }
            // if (percent <= regularPercent) {

            //jumps to take for the regularity
            regularValues.push(element)
            regular = true
            regularCount++
        }
        classification.push({ index, unusual, regular, percent })
    }

    // Calculate the regularity scoring for "good" and "similar" values
    let regularity = 0
    let countRegular = regularValues.length
    if (countRegular > 0) {
        let averageRegular = sum(regularValues) / countRegular

        let sumAbsDiff = 0
        for (let index = 0; index < countRegular; index++) {
            const element = regularValues[index]

            sumAbsDiff += Math.abs(averageRegular - element)
        }
        regularity = ((averageRegular - sumAbsDiff / countRegular) * 100) / averageRegular
        regularity = Math.round(regularity * (countRegular / nbValues))
        regularity = regularity < 0 ? 0 : regularity //fix to prevent negative regularity
        // averageRegular = $this->jumpsArea(regularValues);
        // sumAbsDiff = 0;
        // foreach ($jumps as $jump) {
        //     sumAbsDiff += abs(averageRegular - $jump['area']);
        // }
        // countRegular = count(regularValues);
        // $regularity = round(((averageRegular - (sumAbsDiff / $nbJumps)) * 100) / averageRegular);
    }

    return {
        regularCount,
        unsualCount,
        regularity,
        classification: classification.sort((a, b) => b.percent - a.percent),
    }
}

const getDisciplineIconSrc = (discipline) => {
    return `/images/gifs/${disciplinesAvailable.includes(discipline) ? discipline : 'default'}.gif`
}

const getDisciplineColors = (discipline) => {
    return tailwind.theme.colors.discipline?.[discipline] ?? tailwind.theme.colors.discipline.default
}

const getTotalWeight = (horseWeight, riders) => {
    if (typeof riders === 'object' && !Array.isArray(riders) && riders !== null) {
        riders = [riders]
    }

    let totalRidersWeight = 0
    for (let index = 0; index < riders?.length; index++) {
        totalRidersWeight += parseInt(riders[index]?.weight) || defaultRiderWeight
    }

    return (parseInt(horseWeight) || defaultHorseWeight) + Math.round(totalRidersWeight / riders?.length)
}

export {
    getFasterStride,
    calculateStatisticsByChunk,
    calculateStatistics,
    colorThresholds,
    equilibriumTresholds,
    healthEventTresholds,
    standardDeviation,
    regularityAndClassification,
    getDisciplineIconSrc,
    getDisciplineColors,
    getTotalWeight,
}
